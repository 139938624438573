import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import estadoApp from './estadoApp'
import i18n from '@/plugins/i18n'
import VueQRCodeComponent from 'vue-qr-generator'
import { setRouter } from './api';


setRouter(router);

Vue.component('qr-code', VueQRCodeComponent)

Vue.prototype.$estadoApp = estadoApp

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
