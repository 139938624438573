import Axios from 'axios';
import appLocalStorage from "./appLocalStorage";

let router = null;

export function setRouter(vueRouter) {
  router = vueRouter;
}

export let instanciaAxios = Axios.create({
  baseURL: process.env.VUE_APP_URL_BASE_BACKEND,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json'
  }
})

// Añadir interceptor para manejar errores
instanciaAxios.interceptors.response.use(
  response => {
    // Si la respuesta es exitosa, simplemente retorna la respuesta
    return response;
  },
  error => {
    // Manejar errores
    if (error.response) {

      if (error.response.data.codigo_error == 401) {
        appLocalStorage.remove('accessToken')
      }
    }else{
      appLocalStorage.remove('accessToken')
    }

    return Promise.reject(error);
  }
);



export default {

  establecerAccessToken(access_token) {
    instanciaAxios.defaults.headers.common[ 'Authorization' ] = `Bearer ${appLocalStorage.get('accessToken')}`
  },

  eliminarAccessToken() {
    delete instanciaAxios.defaults.headers.common[ 'Authorization' ]
  },

  iniciarSesion(guid, password) {
    let peticion = {}
    peticion.guid = guid
    peticion.contrasena = password
    return instanciaAxios.post('/sesion/iniciar', peticion)
  },

  establecerNuevaContrasena(correo, contrasena, fecha, hash) {

    let peticion = {};

    peticion.correo = correo;
    peticion.contrasena = contrasena;
    peticion.fecha = fecha;
    peticion.hash = hash;

    return instanciaAxios.post('/establecerNuevaContrasena', peticion)

  },

  preReserva(peticion) {
    instanciaAxios.defaults.headers.common[ 'Authorization' ] = `Bearer ${appLocalStorage.get('accessToken')}`
    return instanciaAxios.post('/preReserva', peticion);
  },

  confirmarPreReserva(peticion) {
    instanciaAxios.defaults.headers.common[ 'Authorization' ] = `Bearer ${appLocalStorage.get('accessToken')}`
    return instanciaAxios.post('/confirmarPreReserva', peticion);
  },

  cederPlaza(peticion) {
    instanciaAxios.defaults.headers.common[ 'Authorization' ] = `Bearer ${appLocalStorage.get('accessToken')}`
    return instanciaAxios.post('/cederPlaza', peticion);
  },

  obtenerIdiomas() {
    instanciaAxios.defaults.headers.common[ 'Authorization' ] = `Bearer ${appLocalStorage.get('accessToken')}`
    return instanciaAxios.get('/obtenerIdiomas');
  },

  editarOperador(peticion) {
    instanciaAxios.defaults.headers.common[ 'Authorization' ] = `Bearer ${appLocalStorage.get('accessToken')}`
    return instanciaAxios.put('/editarOperador', peticion);
  },

  async cerrarSesion() {
    return await instanciaAxios.post('/sesion/cerrar')
  },

  async recuperarContrasena(email) {
    let peticion = {}
    peticion.correoElectronico = email
    return await instanciaAxios.post('/recuperar_contrasena', peticion)
  },

  obtenerInformacionEntidad(email) {
    instanciaAxios.defaults.headers.common[ 'Authorization' ] = `Bearer ${appLocalStorage.get('accessToken')}`
    return instanciaAxios.get('/obtenerInformacionEntidad/' + email);
  },

  obtenerInformacionEntidadMenu() {
    instanciaAxios.defaults.headers.common[ 'Authorization' ] = `Bearer ${appLocalStorage.get('accessToken')}`
    return instanciaAxios.get('/obtenerInformacionEntidadMenu');
  },

  eliminarReserva(localizador) {
    instanciaAxios.defaults.headers.common[ 'Authorization' ] = `Bearer ${appLocalStorage.get('accessToken')}`
    return instanciaAxios.delete('/eliminarReserva/' + localizador);
  },

  guardarNuevaImagen(img) {
    instanciaAxios.defaults.headers.common[ 'Authorization' ] = `Bearer ${appLocalStorage.get('accessToken')}`

    let peticion = {}
    peticion.img = img
    return instanciaAxios.post('/guardarNuevaImagen', peticion);
  }
}
