import { register } from 'register-service-worker'
const VERSION = '1.0.10'
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(`Service Worker versión ${VERSION}`);
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('New content is available; please refresh.')

      // Aquí puedes notificar al usuario que hay una nueva versión disponible
      if (confirm('Nueva versión disponible. ¿Quieres actualizar?')) {
        // Si el usuario acepta, forzamos la actualización
        if (registration.waiting) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          window.location.reload();  // Forzamos la recarga de la página para aplicar la nueva versión
        }
      }
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
